export default class OfferLinkTypesEnum {
    static get PRODUCT() {
        return 'product';
    }

    static get CATEGORY() {
        return 'category';
    }

    static get EXTERNAL() {
        return 'external';
    }

    static get DISCOUNT() {
        return 'discount';
    }

    static get VISUAL() {
        return 'visual';
    }

    static get LOYALTY() {
        return 'loyalty';
    }

    static get MENU_SUMMARY() {
        return 'menu_summary';
    }

    static get options() {
        return {
            PRODUCT: this.PRODUCT,
            CATEGORY: this.CATEGORY,
            EXTERNAL: this.EXTERNAL,
            DISCOUNT: this.DISCOUNT,
            VISUAL: this.VISUAL,
            LOYALTY: this.LOYALTY,
            MENU_SUMMARY: this.MENU_SUMMARY
        };
    }
}
