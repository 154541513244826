<template>
    <tr class="mt-2 w-full bg-white">
        <td class="whitespace-nowrap">
            <div class="my-2 pl-3">
                <span class="inline-block cursor-pointer text-sm leading-5">
                    <venue-selector
                        :venues="venues"
                        :multiple-select="false"
                        :preSelected="link.venueId.$model"
                        :allow-empty="false"
                        :key="index"
                        @selected="onVenueChange"
                        class="no-border-impact max-w-44 w-44 border-gray-100"
                        :class="{
                            error: link.venueId.$error
                        }"
                    />
                </span>
            </div>
        </td>
        <td class="whitespace-nowrap">
            <div class="my-1 pl-3">
                <span
                    class="col-span-2 inline-block cursor-pointer text-sm leading-5"
                >
                    <select
                        v-model.trim="link.type.$model"
                        :class="{
                            'border-red-300': link.type.$error
                        }"
                        id="type"
                        name="type"
                        class="focus:outline-none w-26 inline-block rounded-md border border-gray-300 py-2 pl-3 pr-9 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                        @change="handleTypeChange"
                    >
                        <option
                            v-for="(type, index) of types"
                            :key="index"
                            :value="type"
                            :selected="index === 0"
                        >
                            {{ type }}
                        </option>
                    </select>
                </span>
            </div>
        </td>
        <td class="whitespace-nowrap">
            <div class="relative my-1 flex flex-row items-center pl-3">
                <base-input-field
                    type="text"
                    id="value"
                    :disabled="valueOptionalTypes.includes(link.type.$model)"
                    v-model.trim="link.value.$model"
                    class="w-full"
                    :maxLength="255"
                    :errors="link.value.$error"
                    :placeholder="
                        link.type.$model === 'external'
                            ? 'e.g. https://google.com'
                            : 'e.g. 1234'
                    "
                />
            </div>
        </td>
        <td class="whitespace-nowrap">
            <div class="flex whitespace-nowrap px-2 py-2">
                <div
                    @click="remove(link)"
                    class="group my-1 cursor-pointer text-gray-400"
                >
                    <svg
                        class="h-5 w-5 group-hover:text-red-400"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                    </svg>
                </div>
            </div>
        </td>
    </tr>
</template>
<script>
import { FeaturesEnum, OfferLinkTypesEnum } from '@/enums';
import VenueSelector from '@/components/selectors/VenueSelector';
import BaseInputField from '@/components/base/form/BaseInputField';

export default {
    name: 'OfferLinkGroupListOption',
    components: { BaseInputField, VenueSelector },
    data() {
        return {
            FeaturesEnum,
            types: OfferLinkTypesEnum.options
        };
    },
    props: {
        venues: {
            type: Array,
            default: () => []
        },
        index: {
            type: String
        },
        link: {
            type: Object,
            default: () => ({}),
            required: true
        }
    },
    computed: {
        valueOptionalTypes() {
            return [
                OfferLinkTypesEnum.VISUAL,
                OfferLinkTypesEnum.LOYALTY,
                OfferLinkTypesEnum.MENU_SUMMARY
            ];
        }
    },
    methods: {
        remove(link) {
            this.$emit('remove', link.$model);
        },
        onVenueChange(venueIds) {
            this.link.venueId.$model = venueIds;
        },
        handleTypeChange() {
            if (this.valueOptionalTypes.includes(this.link.type.$model)) {
                this.link.value.$model = null;
            }
        }
    }
};
</script>
