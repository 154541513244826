<template>
    <div class="mt-5 bg-white shadow sm:rounded-md">
        <div class="px-4 sm:col-span-2 sm:px-6 lg:px-8">
            <div class="mt-3 flex flex-col print:block">
                <div class="-mx-4 -my-2 overflow-x-auto py-2 sm:-mx-6 lg:-mx-8">
                    <div
                        class="inline-block min-w-full overflow-hidden align-middle sm:rounded-sm print:overflow-visible"
                    >
                        <div class="max-h-56 overflow-y-auto z-50">
                            <table class="w-full min-w-full px-3 sm:px-4 lg:px-6">
                                <thead class="top-0 sticky z-10">
                                    <tr>
                                        <th
                                            class="border-b border-gray-200 bg-gray-100 px-3 py-1.5 text-left text-xs font-medium text-gray-500 sm:rounded-tl-md sm:px-4"
                                        >
                                            Items <span class="font-mono">({{countOrderItems}})</span>
                                        </th>
                                        <th
                                            class="border-b border-gray-200 bg-gray-100 px-3 py-1.5 text-right text-xs font-medium text-gray-500 sm:px-4"
                                        >
                                            Quantity
                                        </th>

                                        <th
                                            class="border-b border-gray-200 bg-gray-100 px-3 py-1.5 text-right text-xs font-medium text-gray-500 sm:rounded-tr-md sm:px-4"
                                        >
                                            Price
                                        </th>
                                    </tr>
                                </thead>
                                <tbody
                                    class="bg-white"
                                    v-if="order?.items?.length"
                                >
                                    <RefundOrderMenuItem
                                        @selected="handleItemSelection"
                                        :item="item"
                                        v-for="(item, index) in order.items"
                                        :key="item.id + '-' + index"
                                    />
                                </tbody>
                                <tbody
                                    class="bg-white"
                                    v-if="order?.items?.length"
                                >
                                </tbody>
                            </table>
                        </div>
                        <ul class="w-full px-3 pb-1 sm:px-4 border-t">
                            <li class=" text-xs text-gray-600" >
                                <label for="serviceCharge" class="flex py-1.5 flex-auto flex-row justify-between cursor-pointer">
                                     <div class="flex">
                                         <base-checkbox
                                             :disabled="order.serviceCharge === 0"
                                             v-model="selected"
                                             value="serviceCharge"
                                             id="serviceCharge" />

                                         <span class="total"> Service charge </span>
                                     </div>
                                     <span class="font-mono text-gray-900">
                                         {{ (order.serviceCharge / 100) | currency }}
                                     </span>
                                </label>
                            </li>
                            <li
                                class="text-xs text-gray-600"
                                v-if="
                                    order.fulfillmentMethod === 'Delivery' &&
                                    order.deliveryFee
                                "
                            >
                                <label for="tip" class="flex py-1.5 flex-auto flex-row justify-between cursor-pointer">
                                    <div class="flex">
                                        <base-checkbox
                                            :disabled="order.tip === 0"
                                            v-model="selected"
                                            value="deliveryFee"
                                            id="deliveryFee" />

                                        <span class="total"> Delivery Fee </span>
                                    </div>
                                    <span class="font-mono text-gray-900">
                                        {{ (order.deliveryFee / 100) | currency }}
                                    </span>
                                </label>
                            </li>
                            <li
                                class="text-xs text-gray-600"
                                v-if="order.tip > 0"
                            >
                                <label for="tip" class="py-1.5 flex flex-auto flex-row justify-between cursor-pointer">
                                    <div class="flex">
                                        <base-checkbox
                                            :disabled="order.tip === 0"
                                            v-model="selected"
                                            value="tip"
                                            id="tip" />

                                        <span class="total"> Tip </span>
                                    </div>
                                    <span class="font-mono text-gray-900">
                                        {{ (order.tip / 100) | currency }}
                                    </span>
                                </label>
                            </li>
                            <li
                                class="mt-2 flex flex-auto flex-row justify-between text-xs text-gray-600"
                                v-if="order?.orderDiscountCode?.length"
                            >
                                <span class="total">
                                    Discount [{{ order.orderDiscountCode[0].code }}]
                                </span>
                                <span class="font-mono text-red-800">
                                    ({{ (order.discountAmount / 100) | currency }})
                                </span>
                            </li>
                            <li
                                class="mt-2 flex flex-auto flex-row justify-between text-xs text-gray-600"
                                v-if="order?.customer?.piggy?.piggyProductDiscount > 0"
                            >
                                <span class="total">
                                    Loyalty discount
                                </span>
                                <span class="font-mono text-red-800">
                                    ({{ (order.customer.piggy.piggyProductDiscount / 100 ) | currency }})
                                </span>
                            </li>
                            <li
                                class="mt-1.5 h-px border-t border-dashed border-gray-200"
                            >
                            </li>
                            <li
                                class="mt-1 flex flex-auto flex-row justify-between py-1 font-heading text-xs font-bold text-gray-900"
                            >
                                <span class="total"> Total </span>
                                <span class="price">
                                    {{ (order.total / 100) | currency }}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RefundOrderMenuItem from '@/components/RefundOrderMenuItem';

export default {
    name: 'RefundOrderLineItems',
    components: {
        RefundOrderMenuItem
    },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selected: [],
        }
    },
    computed: {
        countOrderItems() {
            if (!this.order || !Array.isArray(this.order.items) || this.order.items.length === 0) {
              return '';
            }
            return this.order.items.reduce((acc, item) => {
                return acc + item.quantity;
            }, 0);
        },
    },
    watch: {
      selected() {
          const refundTotal = this.calculateRefundPrice()

          this.$emit('updateRefundTotal', refundTotal)
      }
    },
    methods: {
        handleItemSelection(item) {
            const index = this.selected.findIndex(selectedItem => selectedItem.id === item.id);
            if (index !== -1) {
                this.selected.splice(index, 1);
            } else {
                this.selected.push(item);
            }
        },
        calculateRefundPrice() {
            return this.selected.reduce((total, item) => {
                if (item.price) {
                    return total + item.price
                } else if (item === 'serviceCharge') {
                    return total + this.order.serviceCharge
                } else if (item === 'tip') {
                    return total + this.order.tip
                } else if (item === 'deliveryFee') {
                    return total + this.order.deliveryFee
                }
            }, 0);
        }
    }
};
</script>
