<template>
    <div class="w-full">
        <label
            :id="labelId"
            class="block text-sm font-medium leading-none text-gray-900"
        >
            {{ label }}
        </label>
        <div class="relative mt-2 flex w-full">
            <span
                v-if="prefix"
                class="inline-flex w-20 items-center overflow-hidden whitespace-nowrap rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"
            >
                {{ prefix }}
            </span>
            <button
                @click="toggleDropdown"
                type="button"
                class="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-black sm:text-sm sm:leading-6"
                :class="{
                    'rounded-l-none': !!prefix
                }"
                aria-haspopup="listbox"
                :aria-expanded="dropdownOpen"
                :aria-labelledby="labelId"
            >
                <span class="w-full truncate">
                    <span class="truncate">{{
                        selectOptionValue
                    }}</span>
                    <span
                        v-if="selectedOption?.[sublabelKey]"
                        class="ml-2 truncate text-gray-500"
                        >{{ selectedOption[sublabelKey] }}</span
                    >
                </span>
                <span
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"
                >
                    <svg
                        class="h-5 w-5 text-gray-400"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        aria-hidden="true"
                    >
                        <path
                            fill-rule="evenodd"
                            d="M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z"
                            clip-rule="evenodd"
                        />
                    </svg>
                </span>
            </button>

            <transition name="fade">
                <ul
                    v-if="dropdownOpen"
                    v-click-outside="onClickOutside"
                    class="absolute z-50 mt-1 max-h-60 w-full w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                    tabindex="-1"
                    role="listbox"
                    :aria-labelledby="labelId"
                    :aria-activedescendant="activeDescendant"
                >
                    <li
                        v-for="(option, index) in localOptions"
                        :key="index"
                        class="relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900"
                        :id="'listbox-option-' + index"
                        role="option"
                        @mouseenter="highlightOption(index)"
                        @mouseleave="highlightOption(null)"
                        @click="selectOption(option)"
                        :class="{
                            'bg-sk-black text-white':
                                highlightedOption === index
                        }"
                    >
                        <div class="flex">
                            <span
                                :class="{
                                    'font-semibold': isSelected(option),
                                    'font-normal': !isSelected(option)
                                }"
                                class="truncate"
                            >
                                {{ option[labelKey] }}
                            </span>
                            <span
                                v-if="option[sublabelKey]"
                                :class="{
                                    'text-indigo-200':
                                        highlightedOption === index,
                                    'text-gray-500': highlightedOption !== index
                                }"
                                class="ml-2 truncate"
                            >
                                {{ option[sublabelKey] }}
                            </span>
                        </div>
                        <span
                            v-if="isSelected(option)"
                            :class="{
                                'text-white': highlightedOption === index,
                                'text-indigo-600': highlightedOption !== index
                            }"
                            class="absolute inset-y-0 right-0 flex items-center pr-4"
                        >
                            <svg
                                class="h-5 w-5"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
                                    clip-rule="evenodd"
                                />
                            </svg>
                        </span>
                    </li>
                </ul>
            </transition>
        </div>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside';

export default {
    name: 'BaseSelectDropdown',
    directives: {
        clickOutside: vClickOutside.directive
    },
    props: {
        value: {
            type: [Object, String, Number],
            default: null
        },
        options: {
            type: Array,
            required: true
        },
        label: {
            type: String,
            default: ''
        },
        labelId: {
            type: String,
            default: 'listbox-label'
        },
        labelKey: {
            type: String,
            default: 'label'
        },
        sublabelKey: {
            type: String,
            default: 'sublabel'
        },
        valueKey: {
            type: String,
            default: 'value'
        },
        emitValueOnly: {
            type: Boolean,
            default: false
        },
        prefix: {
            type: String,
            default: ''
        },
        allowClear: {
            type: Boolean,
            default: false
        },
        clearOptionName: {
            type: String,
            default: 'No option'
        }
    },
    data() {
        return {
            dropdownOpen: false,
            highlightedOption: null,
            selectedOption: null,
            activeDescendant: 'listbox-option-0',
            localOptions: [...this.options]
        };
    },
    created() {
        if (this.allowClear) {
            this.localOptions.unshift({
                [this.labelKey]: this.clearOptionName,
                [this.valueKey]: null
            });
        }

        this.updateSelectedOption(this.value);
    },
    computed: {
        selectOptionValue() {
            if (this.allowClear && !this.selectedOption) {
                return this.clearOptionName;
            }

            if (this.selectedOption) {
                return this.selectedOption[this.labelKey];
            }

            return 'Select option';
        }
    },
    watch: {
        value: {
            handler(newValue) {
                this.updateSelectedOption(newValue);
            },
            immediate: true
        }
    },
    methods: {
        toggleDropdown() {
            this.dropdownOpen = !this.dropdownOpen;
        },
        highlightOption(option) {
            this.highlightedOption = option;
        },
        selectOption(option) {
            this.selectedOption = option;
            this.dropdownOpen = false;
            const emittedValue = this.emitValueOnly
                ? option[this.valueKey]
                : option;

            this.$emit('input', emittedValue);
        },
        updateSelectedOption(newValue) {
            if (this.emitValueOnly) {
                this.selectedOption =
                    this.localOptions.find(
                        option => option[this.valueKey] === newValue
                    ) || null;
            } else {
                this.selectedOption = newValue;
            }
        },
        clearSelection() {
            this.selectedOption = null;
            this.dropdownOpen = false;
            this.$emit('input', null);
        },
        onClickOutside() {
            this.dropdownOpen = false;
        },
        isSelected(option) {
            return (
                this.selectedOption &&
                this.selectedOption[this.valueKey] === option[this.valueKey]
            );
        }
    }
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.25s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>
