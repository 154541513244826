<template>
    <tr @click="toggleSelected(item)" class="cursor-pointer">
        <td
            class="border-t border-gray-100 px-3 py-1.5 sm:px-4"
        >
            <div class="flex items-center">
                <div
                    class="2xl:w-10 2xl:w-10 h-3 w-3 flex-shrink-0 self-start sm:h-4 sm:w-4"
                >
                    <base-checkbox
                        class="mt-2.5"
                        v-model="selected"
                    />
                </div>
                <div class="ml-3">
                    <div class="text-xs font-medium leading-5 text-gray-900">
                        {{ item.name }}
                    </div>
                    <div v-if="item?.modifiers?.length > 0">
                        <div
                            v-for="(modifier, key) in item.modifiers"
                            :key="`${item.id}-${modifier.id}-${key}`"
                            class="ml-1 flex flex-row items-center text-gray-700"
                        >
                            <base-curved-arrow-icon class="h-3 w-3 rotate-180 transform-gpu mr-1" />
                            <span class="pl-1 text-xs leading-5 text-green-600 w-full">
                                {{ modifier.name }}
                            </span>
                        </div>
                    </div>
                    <div v-if="item?.notes?.length > 0" class="ml-1 mt-1 border rounded-md px-1 py-0.5 bg-gray-50 border-dashed inline-flex items-center flex-row whitespace-normal">
                        <base-warning-icon
                            class="max-h-4 max-w-4 text-yellow-700 inline-block"
                        />
                        <span class="pl-1 text-xs leading-5 text-gray-500">
                              {{ item.notes }}
                          </span>
                      </div>
                </div>
            </div>
        </td>
        <td
            class="whitespace-nowrap border-b border-gray-100 px-3 py-0.5 text-right sm:px-4 align-top"
        >
            <div
                class="inline rounded-md bg-sk-silver-grey px-2 py-1 font-mono text-xs font-semibold leading-5 text-gray-900"
            >
                x{{ item.quantity }}
            </div>
        </td>
        <td
            class="whitespace-nowrap border-b border-gray-100 px-3 py-1.5 text-right font-mono sm:px-4 align-top"
        >
            <div class="text-xs leading-5 text-gray-900">
                {{ (countItemPrice / 100) | currency }}
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    name: 'RefundOrderMenuItem',
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            selected: false,
            price: 0
        }
    },
    methods: {
        toggleSelected(item){
            this.selected = !this.selected
            let id = item.id.toString()

            if (item?.modifiers?.length > 0) {
                id += item.modifiers.map(modifier => modifier.id).join('')
            }

            this.$emit('selected', { id, price: this.countItemPrice });
        },
    },
    computed: {
        countItemPrice() {
            let price = this.item.price * this.item.quantity;

            if (this.item.modifiers) {
                this.item.modifiers.map(modifier => {
                    price += modifier.price * this.item.quantity;
                });
            }

            return price;
        }
    }
};
</script>
