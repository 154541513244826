<template>
    <div class="flex w-0 flex-1 flex-col overflow-hidden">
        <toggle-sidebar />

        <main
            class="focus:outline-none relative z-0 flex-1 overflow-y-auto"
            tabindex="0"
        >
            <div class="pb-6 pt-2 md:py-6">
                <div class="mx-auto max-w-5xl px-4 sm:px-6 lg:px-8">
                    <div class="md:flex md:items-center md:justify-between">
                        <div class="min-w-0 flex-1">
                            <router-link
                                :to="{ name: 'offers' }"
                                class="mb-2 flex inline-flex items-center text-sm text-gray-500"
                            >
                                <base-back-icon />
                                <span>Banners</span>
                            </router-link>
                            <BasePageTitle
                                :title="
                                    isEdit ? 'Edit Banner' : 'Create Banner'
                                "
                            />
                        </div>

                        <div
                            class="h-4 w-4"
                            :class="{ 'text-gray-900 spinner': isLoading }"
                        >
                            <span></span>
                        </div>
                    </div>
                </div>

                <div
                    v-if="isLoading"
                    class="relative mx-auto grid max-w-5xl grid-cols-6 gap-6 px-4 py-8 sm:px-6 md:px-8"
                >
                    <div
                        class="top-0 col-span-6 h-auto rounded-md bg-white opacity-90 shadow md:sticky md:col-span-2"
                    >
                        <div class="p-4">
                            <vcl-list :width="250" :height="200" />
                        </div>
                    </div>
                    <div
                        class="col-span-6 overflow-hidden bg-white shadow sm:rounded-md md:col-span-4"
                    >
                        <div class="p-4">
                            <vcl-list :width="400" :height="200" />
                        </div>
                    </div>
                </div>

                <div
                    v-else
                    class="mx-auto mb-40 max-w-5xl px-4 sm:px-6 lg:px-8"
                >
                    <div>
                        <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                            <div class="md:col-span-1">
                                <div class="sm:pl-0 sm:pr-5">
                                    <h3
                                        class="text-base font-semibold leading-6 text-gray-800"
                                    >
                                        Name
                                    </h3>
                                    <p class="mt-2 text-sm text-gray-500">
                                        Add an offer name (visible only to store
                                        admins).
                                    </p>
                                </div>
                            </div>
                            <div class="mt-5 md:col-span-2 md:mt-0">
                                <div class="grid grid-cols-6 gap-3">
                                    <div
                                        class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                                    >
                                        <div class="mt-5 md:col-span-2 md:mt-0">
                                            <div class="rounded-md shadow-sm">
                                                <base-input-field
                                                    id="name"
                                                    v-model="offer.name"
                                                    :errors="
                                                        $v.offer.name.$error
                                                    "
                                                    errorMessage="This field is required"
                                                    placeholder="e.g. Croquettes -10%"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BaseSpacerWithRuler />

                    <div>
                        <div class="mt-2 md:grid md:grid-cols-3 md:gap-6">
                            <div class="md:col-span-1">
                                <div class="sm:pl-0 sm:pr-5">
                                    <h3
                                        class="text-base font-semibold leading-6 text-gray-800"
                                    >
                                        Schedule
                                    </h3>
                                    <p class="mt-2 text-sm text-gray-500">
                                        Schedule the offer to activate and/or
                                        end in the future
                                    </p>
                                </div>
                            </div>
                            <div class="mt-5 md:col-span-2 md:mt-0">
                                <div class="grid grid-cols-6 gap-3">
                                    <div
                                        class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                                    >
                                        <div>
                                            <div class="flex gap-4">
                                                <div
                                                    class="w-full"
                                                    v-auto-animate
                                                >
                                                    <div
                                                        class="flex flex-row items-center"
                                                    >
                                                        <toggle-switch
                                                            v-model="
                                                                enableStartDate
                                                            "
                                                        />
                                                        <label
                                                            class="ml-2 text-sm font-medium text-gray-500"
                                                        >
                                                            Start date
                                                        </label>
                                                    </div>

                                                    <DiscountValiditySelector
                                                        v-if="enableStartDate"
                                                        v-model="startDateRange"
                                                        :disabled="
                                                            !enableStartDate
                                                        "
                                                    />
                                                </div>
                                                <div
                                                    class="w-full"
                                                    v-auto-animate
                                                >
                                                    <div
                                                        class="flex flex-row items-center"
                                                    >
                                                        <toggle-switch
                                                            v-model="
                                                                enableEndDate
                                                            "
                                                        />
                                                        <label
                                                            class="ml-2 text-sm font-medium text-gray-500"
                                                        >
                                                            End date
                                                        </label>
                                                    </div>
                                                    <DiscountValiditySelector
                                                        v-if="enableEndDate"
                                                        v-model="endDateRange"
                                                        :disabled="
                                                            !enableEndDate
                                                        "
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BaseSpacerWithRuler />

                    <div
                        :class="{
                            'invalid-form-field': $v.offer.desktopImage.$error
                        }"
                    >
                        <div class="my-2 md:grid md:grid-cols-3 md:gap-6">
                            <div class="md:col-span-1">
                                <div class="sm:pl-0 sm:pr-5">
                                    <h3
                                        class="text-base font-semibold leading-6 text-gray-800"
                                    >
                                        Desktop Image
                                    </h3>
                                    <p class="mt-2 text-sm text-gray-500">
                                        Upload a image to display at the top of
                                        your store.
                                    </p>
                                    <p class="mt-2 text-sm text-gray-500">
                                        For the best results upload an image
                                        larger than 800x320 pixels.
                                    </p>
                                </div>
                            </div>
                            <div class="mt-5 md:col-span-2 md:mt-0">
                                <div
                                    class="shadow sm:overflow-hidden sm:rounded-md"
                                >
                                    <div
                                        class="space-y-6 bg-white px-4 py-5 sm:p-6"
                                    >
                                        <div>
                                            <uploadcare-inline
                                                ctxName="offer-desktop-image"
                                                inputAcceptTypes="image/*"
                                                @success="onDesktopImageSuccess"
                                            >
                                                <div
                                                    :class="{
                                                        'bg-gray-50':
                                                            offer.desktopImage
                                                    }"
                                                    class="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-6"
                                                >
                                                    <div
                                                        class="text-center"
                                                        v-if="
                                                            !offer.desktopImage
                                                        "
                                                    >
                                                        <p
                                                            class="mt-1 text-xs text-gray-500"
                                                        >
                                                            PNG, JPG, GIF up to
                                                            10MB
                                                        </p>
                                                    </div>
                                                    <div v-else class="w-full">
                                                        <div
                                                            class="relative mx-auto h-40 w-full max-w-md rounded-md bg-cover bg-center hover:opacity-75"
                                                            :style="
                                                                'background-image: url(' +
                                                                offer.desktopImage +
                                                                ');'
                                                            "
                                                        >
                                                            <div
                                                                class="flex h-full items-center justify-center opacity-0 transition duration-500 ease-in-out hover:opacity-100"
                                                            >
                                                                <button
                                                                    @click.stop="
                                                                        offer.desktopImage = null
                                                                    "
                                                                    type="button"
                                                                    class="focus:outline-none ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </uploadcare-inline>
                                        </div>
                                        <div
                                            class="mt-2"
                                            v-if="$v.offer.desktopImage.$error"
                                        >
                                            <span
                                                class="py-2 text-xs text-red-600"
                                            >
                                                Desktop Image is required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BaseSpacerWithRuler />

                    <div
                        :class="{
                            'invalid-form-field': $v.offer.mobileImage.$error
                        }"
                    >
                        <div class="my-2 md:grid md:grid-cols-3 md:gap-6">
                            <div class="md:col-span-1">
                                <div class="sm:pl-0 sm:pr-5">
                                    <h3
                                        class="text-base font-semibold leading-6 text-gray-800"
                                    >
                                        Mobile Image
                                    </h3>
                                    <p class="mt-2 text-sm text-gray-500">
                                        Upload a image to display at the top of
                                        your store.
                                    </p>
                                    <p class="mt-2 text-sm text-gray-500">
                                        For the best results upload an image
                                        larger than 800x320 pixels.
                                    </p>
                                </div>
                            </div>
                            <div class="mt-5 md:col-span-2 md:mt-0">
                                <div
                                    class="shadow sm:overflow-hidden sm:rounded-md"
                                >
                                    <div
                                        class="space-y-6 bg-white px-4 py-5 sm:p-6"
                                    >
                                        <div>
                                            <uploadcare-inline
                                                ctxName="offer-mobile-image"
                                                inputAcceptTypes="image/*"
                                                @success="onMobileImageSuccess"
                                            >
                                                <div
                                                    :class="{
                                                        'bg-gray-50':
                                                            offer.mobileImage
                                                    }"
                                                    class="mt-2 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-6"
                                                >
                                                    <div
                                                        class="text-center"
                                                        v-if="
                                                            !offer.mobileImage
                                                        "
                                                    >
                                                        <p
                                                            class="mt-1 text-xs text-gray-500"
                                                        >
                                                            PNG, JPG, GIF up to
                                                            10MB
                                                        </p>
                                                    </div>
                                                    <div v-else class="w-full">
                                                        <div
                                                            class="relative mx-auto h-40 w-full max-w-md rounded-md bg-cover bg-center hover:opacity-75"
                                                            :style="
                                                                'background-image: url(' +
                                                                offer.mobileImage +
                                                                ');'
                                                            "
                                                        >
                                                            <div
                                                                class="flex h-full items-center justify-center opacity-0 transition duration-500 ease-in-out hover:opacity-100"
                                                            >
                                                                <button
                                                                    @click.stop="
                                                                        offer.mobileImage = null
                                                                    "
                                                                    type="button"
                                                                    class="focus:outline-none ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                                >
                                                                    Remove
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </uploadcare-inline>
                                        </div>
                                        <div
                                            class="mt-2"
                                            v-if="$v.offer.mobileImage.$error"
                                        >
                                            <span
                                                class="py-2 text-xs text-red-600"
                                            >
                                                Mobile Image is required
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BaseSpacerWithRuler />

                    <div
                        :class="{
                            'invalid-form-field': $v.offer.offerLinks.$error
                        }"
                    >
                        <div class="mt-4 md:grid md:grid-cols-3 md:gap-6">
                            <div class="md:col-span-1">
                                <div class="sm:pl-0 sm:pr-5">
                                    <h3
                                        class="text-base font-semibold leading-6 text-gray-800"
                                        >Stores & Links</h3
                                    >
                                    <p class="mt-2 text-sm text-gray-500">
                                        Link the offer to the venues where it
                                        should be presented with a specific type
                                    </p>
                                </div>
                            </div>
                            <div class="mt-5 md:col-span-2 md:mt-0">
                                <div class="grid grid-cols-6 gap-3">
                                    <div
                                        class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                                    >
                                        <div class="mt-5 md:col-span-2 md:mt-0">
                                            <div
                                                class="rounded-sm border border-gray-100 shadow-inner"
                                            >
                                                <table
                                                    class="min-w-full table-auto divide-y divide-gray-200"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                            >
                                                                Venue
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                            >
                                                                Type
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                            >
                                                                Value
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                            >
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <OfferLinkGroupListOptionsContainer
                                                        v-if="offer?.offerLinks?.length"
                                                        v-model="offer.offerLinks"
                                                        helperClass="z-1000"
                                                    >
                                                        <OfferLinkGroupListOption
                                                            v-for="(
                                                                link, index
                                                            ) in $v.offer
                                                                .offerLinks
                                                                .$each.$iter"
                                                            :venues="venues"
                                                            :key="index"
                                                            :index="`${index}-${Date.now()}`"
                                                            :link="link"
                                                            @remove="removeLink"
                                                        />
                                                    </OfferLinkGroupListOptionsContainer>
                                                </table>

                                                <div class="p-3">
                                                    <div
                                                        class="flex justify-center rounded-md bg-white px-6 pb-3 pt-2 text-center"
                                                    >
                                                        <p
                                                            class="mt-1 text-sm text-gray-600"
                                                        >
                                                            <button
                                                                @click.prevent="
                                                                    addLink
                                                                "
                                                                type="button"
                                                                class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                                                            >
                                                                Add an option
                                                            </button>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="mt-2"
                                            v-if="$v.offer.offerLinks.$error"
                                        >
                                            <span
                                                class="py-2 text-xs text-red-600"
                                            >
                                                Complete the required fields in
                                                the form elements above
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <BaseSpacerWithRuler />

                    <div
                        :class="{
                            'invalid-form-field': $v.offer.offerSlots.$error
                        }"
                    >
                        <div class="my-2 md:grid md:grid-cols-3 md:gap-6">
                            <div class="md:col-span-1">
                                <div class="sm:pl-0 sm:pr-5">
                                    <h3
                                        class="text-base font-semibold leading-6 text-gray-800"
                                        >Scheduling</h3
                                    >
                                    <p class="mt-2 text-sm text-gray-500">
                                        Choose which days and times customers
                                        can see your banners.
                                    </p>
                                    <p class="mt-2 text-sm text-gray-500">
                                        If no schedule is set, banners will remain
                                        visible at all times.
                                    </p>
                                </div>
                            </div>
                            <div class="mt-5 md:col-span-2 md:mt-0">
                                <div class="grid grid-cols-6 gap-3">
                                    <div
                                        class="col-span-6 mb-2 rounded-lg bg-white px-4 py-5 shadow sm:rounded-md sm:p-5"
                                    >
                                        <div class="mt-5 md:col-span-2 md:mt-0">
                                            <div
                                                class="rounded-sm border border-gray-100 shadow-inner"
                                            >
                                                <table
                                                    class="min-w-full table-auto divide-y divide-gray-200"
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                            >
                                                                Day
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                            >
                                                                Start Time
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                            >
                                                                End Time
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                class="py-3 pl-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                                                            >
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <OfferSlotGroupListOptionsContainer
                                                        v-if="
                                                            offer.offerSlots &&
                                                            offer.offerSlots
                                                                .length
                                                        "
                                                        v-model="
                                                            offer.offerSlots
                                                        "
                                                        helperClass="z-1000"
                                                    >
                                                        <OfferSlotGroupListOption
                                                            v-for="(
                                                                offerSlot, index
                                                            ) in $v.offer
                                                                .offerSlots
                                                                .$each.$iter"
                                                            :key="index"
                                                            :offerSlot="
                                                                offerSlot
                                                            "
                                                            :index="index"
                                                            @remove="removeSlot"
                                                        />
                                                    </OfferSlotGroupListOptionsContainer>
                                                </table>

                                                <div class="p-3">
                                                    <div
                                                        class="flex justify-center rounded-md bg-white px-6 pb-3 pt-2 text-center"
                                                    >
                                                        <p
                                                            class="mt-1 text-sm text-gray-600"
                                                        >
                                                            <button
                                                                @click.prevent="
                                                                    addSlot
                                                                "
                                                                type="button"
                                                                class="focus:outline-none font-medium text-indigo-600 transition duration-150 ease-in-out hover:text-indigo-500 focus:underline"
                                                            >
                                                                Add an option
                                                            </button>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            class="mt-2"
                                            v-if="$v.offer.offerSlots.$error"
                                        >
                                            <span
                                                class="py-2 text-xs text-red-600"
                                            >
                                                Complete the required fields in
                                                the form elements above
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <BaseSpacerWithRuler />
                    <div
                        class="my-6 ml-4 flex flex-shrink-0 flex-col items-end justify-end"
                    >
                        <div class="flex justify-end py-2">
                            <div v-if="isEdit">
                                <button
                                    v-if="offer.archived"
                                    :disabled="isLoading"
                                    @click="activateOffer"
                                    type="button"
                                    class="focus:outline-none ml-3 inline-flex inline-flex justify-center rounded-md rounded-md border border-transparent px-4 py-2 text-sm font-medium leading-5 text-green-500 transition duration-150 ease-in-out focus:border-green-700 focus:shadow-outline-green active:bg-green-100"
                                >
                                    Activate
                                </button>
                                <button
                                    v-else
                                    :disabled="isLoading"
                                    @click="confirmModalArchiveOffer"
                                    type="button"
                                    class="focus:outline-none ml-3 inline-flex inline-flex justify-center rounded-md rounded-md border border-transparent px-4 py-2 text-sm font-medium leading-5 text-red-500 transition duration-150 ease-in-out focus:border-red-700 focus:shadow-outline-red active:bg-red-100"
                                >
                                    Deactivate
                                </button>
                            </div>
                            <span
                                class="mr-3 inline-flex rounded-md shadow-sm sm:ml-3 sm:mr-0"
                            >
                                <button
                                    :disabled="isLoading"
                                    @click="
                                        isEdit ? editOffer() : createOffer()
                                    "
                                    type="submit"
                                    class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                >
                                    {{
                                        isEdit ? 'Save Banner' : 'Create Banner'
                                    }}
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <toggle-confirm-modal
            @confirm="archiveOffer()"
            :options="{
                title: 'Confirm deactivation',
                description:
                    'To confirm deactivation of the offer, please click the confirm button shown below.'
            }"
        />
    </div>
</template>

<script>
import { VclList } from 'vue-content-loading';
import ToggleSidebar from '@/components/common/ToggleSidebar';
import { mapActions, mapGetters } from 'vuex';
import ToggleConfirmModal from '@/components/modals/ToggleConfirmModal';
import UploadcareInline from '@/components/widgets/UploadcareInline';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';
import DiscountValiditySelector from '@/components/widgets/DiscountValiditySelector';
import BaseSpacerWithRuler from '@/components/base/BaseSpacerWithRuler';
import OfferLinkGroupListOptionsContainer from '@/components/menuBuilder/OfferLinkGroupListOptionsContainer';
import OfferLinkGroupListOption from '@/components/menuBuilder/OfferLinkGroupListOption';
import { required, requiredIf, maxLength } from 'vuelidate/lib/validators';
import BaseInputField from '@/components/base/form/BaseInputField';
import OfferSlotGroupListOptionsContainer from '@/components/menuBuilder/OfferSlotGroupListOptionsContainer';
import OfferSlotGroupListOption from '@/components/menuBuilder/OfferSlotGroupListOption';
import { OfferLinkTypesEnum } from '@/enums';

export default {
    name: 'OfferCreateEdit',
    metaInfo: {
        title: 'Create a offer'
    },
    components: {
        OfferSlotGroupListOption,
        OfferSlotGroupListOptionsContainer,
        BaseInputField,
        OfferLinkGroupListOption,
        OfferLinkGroupListOptionsContainer,
        BaseSpacerWithRuler,
        DiscountValiditySelector,
        ToggleSwitch,
        UploadcareInline,
        ToggleConfirmModal,
        ToggleSidebar,
        VclList
    },
    data() {
        return {
            isLoading: false,
            isEdit: false,
            enableStartDate: false,
            enableEndDate: false,
            startDateRange: {
                startDate: this.$moment(new Date().setHours(0, 0, 0, 0)),
                endDate: this.$moment(new Date().setHours(0, 0, 0, 0))
            },
            endDateRange: {
                startDate: this.$moment(new Date().setHours(0, 0, 0, 0)).add(
                    1,
                    'months'
                ),
                endDate: this.$moment(new Date().setHours(0, 0, 0, 0)).add(
                    1,
                    'months'
                )
            },
            venues: [],
            offer: {
                name: '',
                desktopImage: null,
                mobileImage: null,
                offerLinks: [],
                offerSlots: []
            }
        };
    },
    validations: {
        offer: {
            name: {
                required
            },
            desktopImage: {
                required
            },
            mobileImage: {
                required
            },
            offerLinks: {
                $each: {
                    venueId: { required },
                    type: { required },
                    value: {
                        required: requiredIf(
                            offer =>
                                ![
                                    OfferLinkTypesEnum.VISUAL,
                                    OfferLinkTypesEnum.LOYALTY,
                                    OfferLinkTypesEnum.MENU_SUMMARY
                                ].includes(offer.type)
                        ),
                        maxLength: maxLength(255)
                    }
                }
            },
            offerSlots: {
                $each: {
                    day: { required },
                    startTime: { required },
                    endTime: { required }
                }
            }
        }
    },
    async created() {
        await this.fetchVenues();

        if (this.$route.name === 'editOffer') {
            this.isEdit = true;
            await this.fetchOffer();
        }
    },
    computed: {
        ...mapGetters({
            availableVenues: 'venues/getVenues'
        })
    },
    methods: {
        ...mapActions({
            fetchVenues: 'venues/fetch'
        }),
        onDesktopImageSuccess(event) {
            this.offer.desktopImage = event.cdnUrl;
        },
        onMobileImageSuccess(event) {
            this.offer.mobileImage = event.cdnUrl;
        },
        async activateOffer() {
            this.isLoading = true;

            try {
                await this.$axios.patch(`/offers/${this.offer.id}`, {
                    id: this.offer.id,
                    archived: false
                });

                await this.fetchOffer();

                this.$notify({
                    group: 'settings',
                    title: 'Banner activated'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        confirmModalArchiveOffer() {
            this.$modal.show('toggle-confirm-modal');
        },
        addLink() {
            const item = {
                venueId: null,
                type: 'category',
                value: ''
            };

            this.offer.offerLinks.push(item);
        },
        addSlot() {
            const item = {
                day: 0,
                startTime: '',
                endTime: '',
                active: true
            };

            this.offer.offerSlots.push(item);
        },
        removeLink(product) {
            this.offer.offerLinks = this.offer.offerLinks.filter(
                x => x !== product
            );
        },
        removeSlot(slot) {
            this.offer.offerSlots = this.offer.offerSlots.filter(
                x => x !== slot
            );
        },
        async fetchVenues() {
            try {
                this.isLoading = true;
                const { data } = await this.$axios.get('/venues');

                this.venues = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async createOffer() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    const el = this.$el.querySelector('.invalid-form-field');
                    el.scrollIntoView({ behavior: 'smooth' });
                });

                return false;
            }

            const offer = {
                ...this.offer,
                startDate: this.enableStartDate
                    ? this.startDateRange.startDate
                    : null,
                endDate: this.enableEndDate ? this.endDateRange.endDate : null
            };

            this.isLoading = true;

            try {
                await this.$axios.post('/offers', offer);

                this.$router.push({ name: 'offers' });
                this.$notify({
                    group: 'settings',
                    title: 'New banner created!'
                });
            } catch (error) {
                if (error.response?.status === 409) {
                    window.alert(
                        'Banner already exists, please try a different code' // TODO
                    );
                } else {
                    throw new Error(`API ${error}`);
                }
            } finally {
                this.isLoading = false;
            }
        },

        async editOffer() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                this.$nextTick(() => {
                    const el = this.$el.querySelector('.invalid-form-field');
                    el.scrollIntoView({ behavior: 'smooth' });
                });

                return false;
            }

            const offer = {
                ...this.offer,
                startDate: this.enableStartDate
                    ? this.startDateRange.startDate
                    : null,
                endDate: this.enableEndDate ? this.endDateRange.endDate : null
            };

            this.isLoading = true;

            try {
                await this.$axios.patch(`/offers/${offer.id}`, offer);

                await this.fetchOffer();

                this.$notify({
                    group: 'settings',
                    title: 'Offer updated'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async fetchOffer() {
            this.isLoading = true;

            try {
                const { data } = await this.$axios.get(
                    `/offers/${this.$route.params.id}`
                );

                this.enableStartDate = !!data.startDate;
                this.enableEndDate = !!data.endDate;

                if (this.enableStartDate) {
                    this.startDateRange = {
                        startDate: data.startDate,
                        endDate: data.startDate
                    };
                }

                if (this.enableEndDate) {
                    this.endDateRange = {
                        startDate: data.endDate,
                        endDate: data.endDate
                    };
                }

                this.offer = {
                    id: data.id,
                    name: data.name,
                    desktopImage: data.desktopImage,
                    mobileImage: data.mobileImage,
                    offerLinks: data.offerLinks,
                    offerSlots: data.offerSlots,
                    startDate: data.startDate,
                    endDate: data.endDate,
                    archived: data.archived
                };
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        },
        async archiveOffer() {
            this.isLoading = true;

            try {
                await this.$axios.delete(`/offers/${this.offer.id}`);

                this.$router.push({ name: 'offers' });
            } catch (error) {
                throw new Error(`API ${error}`);
            } finally {
                this.isLoading = false;
            }
        }
    }
};
</script>

<style>
.vue__time-picker.time-picker input {
    @apply m-0;
}

.vue__time-picker.time-picker.error input {
    @apply border-red-300;
}

.custom-selector.no-border-impact.error .multiselect__tags {
    @apply border-red-300 !important;
}

.custom-selector.no-border-impact .multiselect.active .multiselect__tags {
    @apply border-gray-300 !important;
}
</style>
