var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('label',{staticClass:"block text-sm font-medium leading-none text-gray-900",attrs:{"id":_vm.labelId}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"relative mt-2 flex w-full"},[(_vm.prefix)?_c('span',{staticClass:"inline-flex w-20 items-center overflow-hidden whitespace-nowrap rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm"},[_vm._v(" "+_vm._s(_vm.prefix)+" ")]):_vm._e(),_c('button',{staticClass:"relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-black sm:text-sm sm:leading-6",class:{
                'rounded-l-none': !!_vm.prefix
            },attrs:{"type":"button","aria-haspopup":"listbox","aria-expanded":_vm.dropdownOpen,"aria-labelledby":_vm.labelId},on:{"click":_vm.toggleDropdown}},[_c('span',{staticClass:"w-full truncate"},[_c('span',{staticClass:"truncate"},[_vm._v(_vm._s(_vm.selectOptionValue))]),(_vm.selectedOption?.[_vm.sublabelKey])?_c('span',{staticClass:"ml-2 truncate text-gray-500"},[_vm._v(_vm._s(_vm.selectedOption[_vm.sublabelKey]))]):_vm._e()]),_c('span',{staticClass:"pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2"},[_c('svg',{staticClass:"h-5 w-5 text-gray-400",attrs:{"viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M10 3a.75.75 0 01.55.24l3.25 3.5a.75.75 0 11-1.1 1.02L10 4.852 7.3 7.76a.75.75 0 01-1.1-1.02l3.25-3.5A.75.75 0 0110 3zm-3.76 9.2a.75.75 0 011.06.04l2.7 2.908 2.7-2.908a.75.75 0 111.1 1.02l-3.25 3.5a.75.75 0 01-1.1 0l-3.25-3.5a.75.75 0 01.04-1.06z","clip-rule":"evenodd"}})])])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.dropdownOpen)?_c('ul',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.onClickOutside),expression:"onClickOutside"}],staticClass:"absolute z-50 mt-1 max-h-60 w-full w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm",attrs:{"tabindex":"-1","role":"listbox","aria-labelledby":_vm.labelId,"aria-activedescendant":_vm.activeDescendant}},_vm._l((_vm.localOptions),function(option,index){return _c('li',{key:index,staticClass:"relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900",class:{
                        'bg-sk-black text-white':
                            _vm.highlightedOption === index
                    },attrs:{"id":'listbox-option-' + index,"role":"option"},on:{"mouseenter":function($event){return _vm.highlightOption(index)},"mouseleave":function($event){return _vm.highlightOption(null)},"click":function($event){return _vm.selectOption(option)}}},[_c('div',{staticClass:"flex"},[_c('span',{staticClass:"truncate",class:{
                                'font-semibold': _vm.isSelected(option),
                                'font-normal': !_vm.isSelected(option)
                            }},[_vm._v(" "+_vm._s(option[_vm.labelKey])+" ")]),(option[_vm.sublabelKey])?_c('span',{staticClass:"ml-2 truncate",class:{
                                'text-indigo-200':
                                    _vm.highlightedOption === index,
                                'text-gray-500': _vm.highlightedOption !== index
                            }},[_vm._v(" "+_vm._s(option[_vm.sublabelKey])+" ")]):_vm._e()]),(_vm.isSelected(option))?_c('span',{staticClass:"absolute inset-y-0 right-0 flex items-center pr-4",class:{
                            'text-white': _vm.highlightedOption === index,
                            'text-indigo-600': _vm.highlightedOption !== index
                        }},[_c('svg',{staticClass:"h-5 w-5",attrs:{"viewBox":"0 0 20 20","fill":"currentColor","aria-hidden":"true"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z","clip-rule":"evenodd"}})])]):_vm._e()])}),0):_vm._e()])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }