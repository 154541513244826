<template>
    <div>
        <div class="md:flex md:items-center md:justify-between">
            <div class="min-w-0 flex-1">
                <router-link
                    :to="{ name: 'editVenue' }"
                    class="mb-2 flex inline-flex items-center text-sm text-gray-500"
                >
                    <base-back-icon />
                    <span>Settings</span>
                </router-link>
                <base-page-title title="Lightspeed Integration" />
            </div>

            <BaseSpinner :isLoading="isLoading" />
        </div>
        <div>
            <div class="mt-4 grid-cols-1 md:grid md:grid-cols-3 md:gap-6">
                <div class="md:col-span-1">
                    <div class="sm:pl-0 sm:pr-5">
                        <h3
                            class="text-base font-semibold leading-6 text-gray-800"
                        >
                            Details
                        </h3>
                        <p class="mt-2 text-sm text-gray-500">
                            Connect storekit to Lightspeed K-Series POS. When
                            enabled, your orders will be automatically imported
                            into Lightspeed.
                            <br /><br />If you don't have a Lightspeed account
                            yet, you can learn how to
                            <a
                                class="text-indigo-600"
                                href="https://lightspeedpos.com/"
                                target="_blank"
                            >
                                create one here
                            </a>
                        </p>
                    </div>
                </div>
                <div class="mt-5 md:col-span-2 md:mt-0" v-if="!isLoading">
                    <div class="shadow sm:overflow-hidden sm:rounded-md">
                        <div class="space-y-6 bg-white px-4 py-5 sm:p-12">
                            <div
                                class="grid grid-cols-1 gap-6 md:grid-cols-3"
                                v-if="!lightspeed.setup"
                            >
                                <div class="text-center sm:col-span-6">
                                    <span
                                        class="mt-2 inline-flex rounded-md shadow-sm"
                                    >
                                        <button
                                            @click="authorise"
                                            :disabled="isLoading"
                                            type="button"
                                            class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                        >
                                            <img
                                                class="mr-2"
                                                src="https://ucarecdn.com/d9c88ec0-3051-4966-a467-ca26e94489b6/-/format/auto/"
                                                alt=""
                                                width="16"
                                                height="16"
                                            />
                                            Connect to Lightspeed
                                        </button>
                                    </span>
                                </div>
                            </div>

                            <div
                                class="grid grid-cols-1 gap-6 md:grid-cols-3"
                                v-else
                            >
                                <div class="sm:col-span-6">
                                    <label
                                        for="floorplan"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Fetch Lightspeed Floorplan
                                    </label>
                                    <span
                                        @click="displayConfirmModal"
                                        class="mt-2 inline-flex rounded-md shadow-sm"
                                    >
                                        <button
                                            id="floorplan"
                                            type="button"
                                            class="focus:outline-none inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out hover:text-gray-500 focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800"
                                        >
                                            Sync floorplan
                                        </button>
                                    </span>
                                    <toggle-confirm-modal
                                        key="floorplan"
                                        @confirm="syncLightspeedFloorplan()"
                                        :options="{
                                            title:
                                                'Sync floorplan from Lightspeed',
                                            description:
                                                'Import new tables and areas from Lightspeed. We\'ll also remove tables deleted from Lightspeed.'
                                        }"
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="lightspeedMenu"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Lightspeed Menu
                                    </label>
                                    <div>
                                        <select
                                            id="menu"
                                            name="menu"
                                            @change="selectMenu"
                                            class="focus:outline-none mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                        >
                                            <option
                                                v-show="!selectedMenu"
                                                selected
                                                disabled
                                                value=""
                                                >No menu</option
                                            >
                                            <option
                                                v-for="menu in lightspeedMenus"
                                                :value="menu.ikentooMenuId"
                                                :key="menu.ikentooMenuId"
                                                :selected="
                                                    selectedMenu.ikentooMenuId ===
                                                    menu.ikentooMenuId
                                                "
                                            >
                                                {{ menu.menuName }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="dontUpdateImages"
                                        class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Don't update menu images
                                    </label>
                                    <toggle-switch
                                        id="dontUpdateImages"
                                        class="mt-2"
                                        v-model.trim="
                                            venue.integrations.ikentoo
                                                .dontUpdateImages
                                        "
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="dontUpdateDescriptions"
                                        class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Don't update menu description
                                    </label>
                                    <toggle-switch
                                        id="dontUpdateDescriptions"
                                        class="mt-2"
                                        v-model.trim="
                                            venue.integrations.ikentoo
                                                .dontUpdateDescriptions
                                        "
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <fieldset>
                                        <legend
                                            class="block text-sm font-medium leading-5 text-gray-700"
                                        >
                                            Account Profile Codes
                                        </legend>
                                        <div
                                            class="mt-2 flex rounded-md shadow-sm"
                                        >
                                            <base-select-dropdown
                                                v-model="
                                                    venue.integrations.ikentoo
                                                        .fulfillmentMethods
                                                        .pickup
                                                "
                                                :options="
                                                    lightspeedOrderProfiles
                                                "
                                                valueKey="code"
                                                labelKey="name"
                                                emitValueOnly
                                                prefix="Pickup"
                                                class="-mt-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div
                                            class="mt-1 flex rounded-md shadow-sm"
                                        >
                                            <base-select-dropdown
                                                v-model="
                                                    venue.integrations.ikentoo
                                                        .fulfillmentMethods
                                                        .delivery
                                                "
                                                :options="
                                                    lightspeedOrderProfiles
                                                "
                                                valueKey="code"
                                                labelKey="name"
                                                emitValueOnly
                                                prefix="Delivery"
                                                class="-mt-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                        <div
                                            class="mt-1 flex rounded-md shadow-sm"
                                        >
                                            <base-select-dropdown
                                                v-model="
                                                    venue.integrations.ikentoo
                                                        .fulfillmentMethods
                                                        .inStore
                                                "
                                                :options="
                                                    lightspeedOrderProfiles
                                                "
                                                valueKey="code"
                                                labelKey="name"
                                                prefix="In Store"
                                                emitValueOnly
                                                class="-mt-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                            />
                                        </div>
                                    </fieldset>
                                </div>

                                <div
                                    class="sm:col-span-6"
                                    v-if="!settings.acceptsInStore"
                                >
                                    <label
                                        for="deliverySku"
                                        v-tooltip="
                                            'PLU of a product to map order delivery fees to'
                                        "
                                        class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Delivery Product Code (SKU)
                                        <svg
                                            class="ml-2 h-4 w-4"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </label>
                                    <input
                                        id="deliverySku"
                                        type="text"
                                        placeholder="eg AJDWNAUD1231D9021="
                                        v-model="
                                            venue.integrations.ikentoo
                                                .deliveryFeeSku
                                        "
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                    />
                                </div>

                                <div class="sm:col-span-6">
                                    <label
                                        for="serviceChargeSku"
                                        v-tooltip="
                                            'PLU of a product to map order service charge to'
                                        "
                                        class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Service Charge Product Code (SKU)
                                        <svg
                                            class="ml-2 h-4 w-4"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </label>
                                    <input
                                        id="serviceChargeSku"
                                        type="text"
                                        placeholder="eg AJDWNAUD1231D9021="
                                        v-model="
                                            venue.integrations.ikentoo
                                                .serviceChargeSku
                                        "
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                    />
                                </div>

                                <div class="sm:col-span-6">
                                    <label
                                        for="discountSku"
                                        v-tooltip="
                                            'PLU of a product to map order discount to'
                                        "
                                        class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Discount Product Code (SKU)
                                        <svg
                                            class="ml-2 h-4 w-4"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </label>
                                    <input
                                        id="discountSku"
                                        type="text"
                                        placeholder="eg AJDWNAUD1231D9021="
                                        v-model="
                                            venue.integrations.ikentoo
                                                .discountCodeSku
                                        "
                                        class="focus:outline-none form-input mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 shadow-sm transition duration-150 ease-in-out focus:border-blue-300 focus:shadow-outline-blue sm:text-sm sm:leading-5"
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="paymentMethod"
                                        class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Payment Method
                                    </label>
                                    <base-select-dropdown
                                        id="paymentMethod"
                                        v-model="
                                            venue.integrations.ikentoo
                                                .paymentMethod
                                        "
                                        :options="lightspeedPaymentMethods"
                                        valueKey="code"
                                        labelKey="name"
                                        emitValueOnly
                                        class="-mt-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="cashAsPaid"
                                        class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Send cash payments as paid
                                    </label>
                                    <toggle-switch
                                        id="cashAsPaid"
                                        class="mt-2"
                                        v-model.trim="
                                            venue.integrations.ikentoo
                                                .sendCashAsPaid
                                        "
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="removeEmail"
                                        class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Don't send customer email address to
                                        Lightspeed
                                    </label>
                                    <toggle-switch
                                        class="mt-2"
                                        v-model="
                                            venue.integrations.ikentoo
                                                .removeEmail
                                        "
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="bufferOrders"
                                        v-tooltip="
                                            'Orders are buffered in storekit and are only sent to the POS when they need to be prepared. This is calculated using the preparation time set for delivery and pickup orders.'
                                        "
                                        class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Buffer Orders
                                        <svg
                                            class="ml-2 h-4 w-4"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fill-rule="evenodd"
                                                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                                                clip-rule="evenodd"
                                            ></path>
                                        </svg>
                                    </label>

                                    <toggle-switch
                                        class="mt-2"
                                        v-model="
                                            venue.integrations.ikentoo
                                                .bufferOrders
                                        "
                                    />
                                </div>
                              <div
                                  class="sm:col-span-6"
                                  v-if="
                                        venue.integrations.ikentoo.bufferOrders
                                    "
                              >
                                <label
                                    class="block text-sm font-medium text-gray-700"
                                >
                                  Buffer (in minutes)
                                </label>
                                <input
                                    v-model.number="
                                            venue.integrations.ikentoo.buffer
                                        "
                                    class="form-input mt-1 block w-full sm:text-sm sm:leading-5"
                                    :class="{
                                            'border-red-300':
                                                $v.venue.integrations.ikentoo
                                                    .buffer.$error
                                        }"
                                    placeholder="eg 30"
                                    type="number"
                                    min="0"
                                    max="720"
                                />

                                <span
                                    v-if="
                                            $v.venue.integrations.ikentoo.buffer
                                                .$error
                                        "
                                    class="text-red-400"
                                >
                                        {{ bufferError }}
                                    </span>
                              </div>
                                <div class="sm:col-span-6" v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)">
                                  <label
                                      for="menuLoadAccountProfile"
                                      class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                  >
                                    Specify account profile when syncing menu
                                  </label>
                                  <base-select-dropdown
                                        id="menuLoadAccountProfile"
                                        v-model="
                                            venue.integrations.ikentoo
                                                .menuLoadAccountProfile
                                        "
                                        :options="lightspeedOrderProfiles"
                                        valueKey="code"
                                        labelKey="name"
                                        emitValueOnly
                                        allowClear
                                        clearOptionName="No profile"
                                        class="-mt-2 block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="subscreensAsCategories"
                                        class="block flex items-center text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Import Sub-screens as categories
                                    </label>

                                    <toggle-switch
                                        id="subscreensAsCategories"
                                        class="mt-2"
                                        v-model="
                                            venue.integrations.ikentoo
                                                .subscreensAsCategories
                                        "
                                    />
                                </div>
                                <div class="sm:col-span-6">
                                    <label
                                        for="enabled"
                                        class="block text-sm font-medium leading-5 text-gray-700"
                                    >
                                        Integration Status
                                    </label>

                                    <div class="mt-2 flex items-start">
                                        <div>
                                            <span
                                                role="checkbox"
                                                tabindex="0"
                                                :class="{
                                                    'bg-gray-200': !lightspeed.enabled,
                                                    'bg-indigo-600':
                                                        lightspeed.enabled
                                                }"
                                                @click="toggleEnabled"
                                                @keydown.space.prevent="
                                                    lightspeed.enabled = !lightspeed.enabled
                                                "
                                                class="focus:outline-none relative block h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:shadow-outline"
                                            >
                                                <span
                                                    aria-hidden="true"
                                                    :class="{
                                                        'translate-x-5':
                                                            lightspeed.enabled,
                                                        'translate-x-0': !lightspeed.enabled
                                                    }"
                                                    class="relative inline-block h-5 w-5 transform rounded-full bg-white shadow transition duration-200 ease-in-out"
                                                >
                                                    <span
                                                        :class="{
                                                            'opacity-0 duration-100 ease-out':
                                                                lightspeed.enabled,
                                                            'opacity-100 duration-200 ease-in': !lightspeed.enabled
                                                        }"
                                                        class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                                    >
                                                        <svg
                                                            class="h-3 w-3 text-gray-400"
                                                            fill="none"
                                                            viewBox="0 0 12 12"
                                                        >
                                                            <path
                                                                d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                            />
                                                        </svg>
                                                    </span>
                                                    <span
                                                        :class="{
                                                            'opacity-100 duration-200 ease-in':
                                                                lightspeed.enabled,
                                                            'opacity-0 duration-100 ease-out': !lightspeed.enabled
                                                        }"
                                                        class="absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                                                    >
                                                        <svg
                                                            class="h-3 w-3 text-indigo-600"
                                                            fill="currentColor"
                                                            viewBox="0 0 12 12"
                                                        >
                                                            <path
                                                                d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z"
                                                            />
                                                        </svg>
                                                    </span>
                                                </span>
                                            </span>
                                        </div>

                                        <p class="pl-4 text-sm text-gray-500"
                                            >{{
                                                lightspeed.enabled
                                                    ? 'Enabled - sending orders to Lightspeed'
                                                    : 'Disabled - not sending orders to Lightspeed'
                                            }}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BaseSpacerWithRuler />

        <div class="my-6 ml-4 flex flex-shrink-0 justify-end">
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="updateIntegration"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-indigo-500 focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700"
                    :disabled="isLoading"
                >
                    Save
                </button>
            </span>
        </div>

        <div
            v-if="isFeatureAvailable(FeaturesEnum.INTERNAL)"
            class="my-6 ml-4 flex flex-shrink-0 justify-end"
        >
            <span
                class="inline-flex rounded-md shadow-sm"
                @click="disconnectConnection"
            >
                <button
                    type="button"
                    class="focus:outline-none relative inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium leading-5 text-white hover:bg-red-500 focus:border-red-700 focus:shadow-outline-indigo active:bg-red-700"
                >
                    Disconnect Auth
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {  required,
  minValue,
  maxValue,
  requiredIf } from 'vuelidate/lib/validators';
import { FeaturesEnum } from '@/enums';
import ToggleConfirmModal from '@/components/modals/ToggleConfirmModal';
import ToggleSwitch from '@/components/formComponents/ToggleSwitch';

export default {
    name: 'LightspeedSettings',
    metaInfo: {
        title: 'Lightspeed Integration'
    },
    props: {
        venueId: {
            type: Number,
            required: false
        },
        venue: {
            type: Object,
            required: false
        }
    },
    components: {
        ToggleConfirmModal,
        ToggleSwitch
    },
    data() {
        return {
            isLoading: true,
            settings: { ...this.venue },
            lightspeed: {
                setup: false,
                clientId: '',
                clientSecret: '',
                enabled: false
            },
            lightspeedMenus: [],
            lightspeedOrderProfiles: [],
            lightspeedPaymentMethods: [],
            isLightspeedAuthorised: false,
            FeaturesEnum,
            selectedMenu: '',
            bufferError: 'Buffer must be a value between 0 - 720',
            timer: null
        };
    },
    validations: {
        lightspeed: {
            clientId: {
                required(value) {
                    return this.lightspeed.enabled ? !!value.length : true;
                }
            },
            enabled: {
                required
            }
        },
        venue: {
            integrations: {
                ikentoo: {
                    buffer: {
                        minValue: minValue(0),
                        maxValue: maxValue(720),
                        requiredIf: requiredIf(obj => obj.bufferOrders)
                    }
                }
            }
        }
    },
    async mounted() {
        await this.checkIsLightspeedAuthorised();

        if (
            this.venue.posProvider === 'ikentoo' &&
            this.isLightspeedAuthorised
        ) {
            this.lightspeed.setup = true;
            await this.fetchLightspeedMenus();

            await this.fetchLightspeedOrderProfiles();

            await this.fetchLightspeedPaymentMethods();

            const currentLightspeedMenu = this.lightspeedMenus.find(
                ({ ikentooMenuId }) =>
                    ikentooMenuId.toString() === this.venue.menu[0]?.posId
            );

            if (currentLightspeedMenu) {
                this.selectedMenu = currentLightspeedMenu;
            }

            this.lightspeed.clientId = this.venue.integrations.ikentoo.clientId;
            this.lightspeed.enabled = this.venue.integrations.ikentoo.enabled;
        }

        this.isLoading = false;
    },
    computed: {
        ...mapGetters({
            isFeatureAvailable: 'user/isFeatureAvailable'
        })
    },
    methods: {
        async authorise() {
            if (!this.isLightspeedAuthorised) {
                window.open(
                    `${process.env.VUE_APP_LIGHTSPEED_K_AUTH_URL}/oauth/authorize?response_type=code&client_id=${process.env.VUE_APP_LIGHTSPEED_K_CLIENT_ID}&redirect_uri=https://dashboard.storekit.com/auth/lightspeed&state=${this.venueId}`,
                    '_blank'
                );

                window.open('', '_self').close();
            } else {
                this.$router.push({
                    name: 'lightspeedAuth',
                    query: {
                        state: this.venue.id
                    }
                });
            }
        },
        async disconnectConnection() {
            try {
                await this.$axios.post('/ikentoo/disconnect', {
                    venueId: this.venueId
                });
                this.$notify({
                    group: 'settings',
                    duration: 1000,
                    text: 'Lightspeed disconnected'
                });
                window.location.reload();
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async syncLightspeedFloorplan() {
            try {
                await this.$axios.post('/ikentoo/floorplans', {
                    venueId: this.venueId
                });

                this.$modal.hide('toggle-confirm-modal');

                this.$notify({
                    group: 'settings',
                    duration: 200,
                    text: 'Floorplan synced'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async fetchLightspeedMenus() {
            try {
                const { data } = await this.$axios.get('/ikentoo/menu-list', {
                    params: {
                        venueId: this.venueId
                    }
                });

                this.lightspeedMenus = data;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async fetchLightspeedOrderProfiles() {
            try {
                const { data } = await this.$axios.get('/ikentoo/order-profiles', {
                    params: {
                        venueId: this.venueId
                    }
                });

                this.lightspeedOrderProfiles = Array.isArray(data) ? data : [];
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async fetchLightspeedPaymentMethods() {
            try {
                const { data } = await this.$axios.get('/ikentoo/payment-methods', {
                    params: {
                        venueId: this.venueId
                    }
                });

                this.lightspeedPaymentMethods = Array.isArray(data) ? data : [];
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async selectMenu(event) {
            try {
                await this.$axios.patch(`/menus/${this.venue.menu[0].id}`, {
                    posId: event.target.value
                });

                this.$notify({
                    group: 'settings',
                    duration: 200,
                    text: 'Menu selected'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async checkIsLightspeedAuthorised() {
            try {
                const { data } = await this.$axios.get('/account/check', {
                    params: {
                        columnName: 'ikentoo'
                    }
                });

                this.isLightspeedAuthorised = !data ? false : true;
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        async toggleEnabled() {
            try {
                this.lightspeed.enabled = !this.lightspeed.enabled;
                await this.$axios.post('/ikentoo/toggle', {
                    venueId: this.venueId,
                    enabled: this.lightspeed.enabled
                });
            } catch (error) {
                this.lightspeed.enabled = !this.lightspeed.enabled;
                throw new Error(`API ${error}`);
            }
        },
        async updateIntegration() {
            try {
                await this.$axios.post('/ikentoo/update', {
                    venueId: this.venueId,
                    fulfillmentMethods: this.venue.integrations.ikentoo
                        .fulfillmentMethods,
                    discountCodeSku: this.venue.integrations.ikentoo
                        .discountCodeSku,
                    serviceChargeSku: this.venue.integrations.ikentoo
                        .serviceChargeSku,
                    deliveryFeeSku: this.venue.integrations.ikentoo
                        .deliveryFeeSku,
                    paymentMethod: this.venue.integrations.ikentoo
                        .paymentMethod,
                    sendCashAsPaid: this.venue.integrations.ikentoo
                        .sendCashAsPaid,
                  removeEmail: this.venue.integrations.ikentoo.removeEmail,
                    bufferOrders: this.venue.integrations.ikentoo.bufferOrders,
                    buffer: this.venue.integrations.ikentoo.buffer,
                    subscreensAsCategories: this.venue.integrations.ikentoo.subscreensAsCategories,
                    dontUpdateImages: this.venue.integrations.ikentoo
                        .dontUpdateImages,
                    dontUpdateDescriptions: this.venue.integrations.ikentoo
                        .dontUpdateDescriptions,
                    menuLoadAccountProfile: this.venue.integrations.ikentoo
                        .menuLoadAccountProfile
                });
                this.$notify({
                    group: 'settings',
                    duration: 200,
                    text: 'Settings updated'
                });
            } catch (error) {
                throw new Error(`API ${error}`);
            }
        },
        displayConfirmModal() {
            this.$modal.show('toggle-confirm-modal');
        }
    }
};
</script>
